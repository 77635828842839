<template>
  <div class="container-fluid card-body">
    <Load :isLoading="isLoading" />
    <div class="row">
      <div class="col">
        <h1>Modelos de treinamentos</h1>
      </div>
      <div class="col">
        <router-link to="modelos/cadastro">
          <button type="button" class="btn btn-primary float-right btn-hover btn-dark-color" v-if="permissoes > 1">CADASTRAR</button>
        </router-link>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <MyTable
          :searchable="true"
          :data="treinamentos"
          :columns="columns"
          :itemsPerPage="10">
          <template v-slot:row="{ row }">
            <td>
              {{row.nome_atividade}}
            </td>
            <td>
              {{cargaHorariaTotal(row)}}
            </td>
            <td>
              {{row.dias_reciclagem}}
            </td>
            <td class="float-right d-flex">
              <i class="fas fa-edit mr-1 btn btn-warning " title="Editar modelo" @click="editarItem(row.id)" v-if="permissoes > 1"></i>
              <i class="fas fa-trash-alt btn btn-danger" title="Deletar modelo" @click="deletarItem(row)" v-if="permissoes > 2"></i>
            </td>
          </template>
        </MyTable>
      </div>
    </div>
  </div>
</template>

<script>
import MyTable from './../../../uicomponents/MyTable'
import treinamentoModelosRepository from './../../../services/api/treinamentoModelosRepository'
export default {
  name: 'TreinamentosModelos',
  components: {
    MyTable
  },
  data(){
    return {
      isLoading: false,
      permissoes: [],
      columns: [
        {
          label: 'Nome atividade',
          field: 'nome_atividade',
          sortable: 'nome_atividade'
        },
        {
          label: 'Carga horária',
          field: 'carga_horaria_pratica',
          sortable: ''
        },
        {
          label: 'Tempo para reciclagem',
          field: 'dias_reciclagem',
          sortable: ''
        },
        {
          label: 'Ações',
          field: 'actions',
          sortable: ''
        }
      ],
      treinamentos: []
    }
  },
  created () {
    this.fetch()
    this.permissoes = this.$store.getters.permissoes['Treinamento']['Tipos de treinamento']
  },
  methods: {
    editarItem (id) {
      this.$router.push({ name: 'TreinamentosModelosCadastro', query: { id: id } })
    },
    cargaHorariaTotal(item) {
      let splitTime1= item.carga_horaria_pratica.split(':')
      let splitTime2= item.carga_horaria_teorica.split(':')

      let hour = parseInt(splitTime1[0])+parseInt(splitTime2[0])
      let minute = parseInt(splitTime1[1])+parseInt(splitTime2[1])

      hour = hour + Math.floor(minute/60)
      minute = (minute%60).toString()
      minute = minute.length == 1 ? minute+'0' : minute

      return `${hour}:${minute}`
    },
    deletarItem (treinamento) {
      let _this = this
      this.$swal({
        icon: 'warning',
        title: 'Remover treinamento',
        text: `Deseja deletar a ${treinamento.nome}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho certeza!',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.deletarTreinamento(treinamento)
        }
      }).then(function(result) {
        if (result.value) {
          _this.$swal({
            icon: 'success',
            text: 'Registro removido com sucesso!'
          }).then(() => {
            _this.fetch()
          })
          
        } else {
          console.log('FAIL')
        }
      })
    },
    deletarTreinamento (treinamento) {
      this.isLoading = true
      treinamentoModelosRepository.deletarModeloTreinamento(treinamento.id).then((response) => {
        this.isLoading = false
        return response.data['success']
      }).catch(() => {
        this.isLoading = false
        return false
      })
    },
    fetch () {
      this.isLoading = true
      treinamentoModelosRepository.listarModelosTreinamento().then(response => {
        if(response.data['success']) {
          this.treinamentos = response.data['data']
        }
        this.isLoading = false
      }).catch( () => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style>

</style>